<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentReport /> 
</template>
<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentReport from '@/components/team/dashboard/modules/report/ContentReport.vue'

export default 
{
  name: 'Report',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentReport
  }
}
</script>
<style>


</style>